import React from 'react'
import user from '../svg/user.svg'

export default class LoginForm extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            username: '',
            password: '',
            errors: {
                username: {
                    show: false,
                    message: ''
                },
                password: {
                    show: false,
                    message: ''
                },
                request: {
                    show: false,
                    message: ''
                }
            },
            buttonLoader: false
        }

        this.inputChange = this.inputChange.bind(this)
        this.loginAction = this.loginAction.bind(this)
        this.onEnter = this.onEnter.bind(this)
        this.loaderToggle = this.loaderToggle.bind(this)
    }

    inputChange = (event) => {
        let name = event.target.name
        let value = event.target.value

        this.setState({
            [name]: value
        })

        this.errorState(name, value, "length")

    }

    errorState = (name, value, type = "") => {
        let status = false

        if (type === "length") {
            if (value.length < 1) {
                this.setState({
                    errors: {
                        ...this.state.errors,
                        [name]: {
                            show: true,
                            message: [name[0].toUpperCase()] + [name.substring(1)] + ' is required!'
                        }
                    }
                })
            } else {
                this.setState({
                    errors: {
                        ...this.state.errors,
                        [name]: {
                            show: false,
                            message: ''
                        }
                    }
                })
                status = true
            }
        } else {
            this.setState({
                errors: {
                    ...this.state.errors,
                    [name]: {
                        show: value,
                        message: value ? "Invalid Username or Password" : ""
                    }
                }
            })
            status = value
        }

        return status
        
    }

    onEnter = (event) => {
        if (event.charCode === 13) {
            this.loginAction()
        }
    }

    loaderToggle = () => {
        this.setState({
            buttonLoader: !this.state.buttonLoader
        })
    }

    loginAction = () => {
        let {username, password} = this.state
        
        if (this.errorState("username", username, "length") && this.errorState("password", password, "length")) {
            this.errorState("request", false)
            this.loaderToggle()
            let data_form = new FormData()
            data_form.append("username", this.state.username)
            data_form.append("userpass", this.state.password)

            fetch("/controller/ajax/login_user.php", {
                method: "POST",
                body: data_form
            })
            .then(response => response.json())
            .then(data => {
                if (data.status === "completed") {
                    this.errorState("request", false)
                    window.location.href = "http://user.maxindo.net.id/index.php"
                } else {
                    this.errorState("request", true)
                }
            })
            .then(() => {
                setTimeout(() => {
                    this.loaderToggle()
                }, 500)
            })
        }
    }

    render() {

        let {username, password, buttonLoader} = this.state
        let errorUsername = this.state.errors.username
        let errorPassword = this.state.errors.password
        let errorRequest  = this.state.errors.request

        return (
            <div className="card border-0 shadow mb-5">
                <div className="card-body" style={{marginLeft: "15px", marginRight: "15px"}}>
                    <img src={user} className="d-block mt-4 mb-2 mx-auto img-fluid" style={{width: "55px"}} alt="User Login" />
                    <h4 className="text-center mb-4" style={{color: "#34495E", fontWeight: "400"}}>User Login</h4>
                    
                    <div className="form-group mb-3">
                        <label htmlFor="username">Username</label>
                        <input 
                            type="text" 
                            className="form-control shadow-none" 
                            id="username" 
                            name="username" 
                            value={username} 
                            onChange={(event) => this.inputChange(event)}
                        />
                        {errorUsername.show && (
                            <span className="text-danger">{errorUsername.message}</span>
                        )}
                    </div>

                    <div className="form-group mb-4">
                        <label htmlFor="password">Password</label>
                        <input 
                            type="password" 
                            className="form-control shadow-none" 
                            id="password" 
                            name="password" 
                            value={password} 
                            onChange={(event) => this.inputChange(event)}
                            onKeyPress={this.onEnter}
                        />
                        {errorPassword.show && (
                            <span className="text-danger">{errorPassword.message}</span>
                        )}
                    </div>

                    <div className="form-group mb-5">
                        {buttonLoader ? (
                            <button className="btn btn-lg btn-success btn-block shadow-none" disabled>
                                <span className="spinner-border spinner-border-button"></span> Processing...
                            </button>
                        ) : (
                            <button className="btn btn-lg btn-success btn-block shadow-none" onClick={this.loginAction}>Login</button>
                        )}
                        {errorRequest.show && (
                            <span className="text-danger text-center d-block mt-3">{errorRequest.message}</span>
                        )}
                    </div>

                </div>
            </div>
        )
    }
}
