import React from "react";
import { menu } from "../data/listMenu";
import caretDown from '../svg/caret-down.svg'

export default function MobileDrawer(props) {
  return (
    <React.Fragment>
      <div
        className="d-block d-sm-none drawer left-drawer bg-white shadow hide-drawer"
        id="left-drawer"
      >
        <div className="drawer-header p-3">
          <img
            src="https://www.maxindo.net.id/wp-content/uploads/2014/08/favicon.ico"
            className="img-fluid d-inline-block mr-1"
            style={{ width: "34px" }}
            alt="Maxindo Logo"
          />
          <h5
            className="d-inline-block"
            style={{ fontWeight: "400", fontSize: "22px" }}
          >
            Maxindo Mitra Solusi
          </h5>
        </div>
        <ul className="nav flex-column">
          {menu.map((item, index) => {
              if (item.list.length) {
                return (
                    <li className="nav-item" key={index}>
                        <a href={"#child1-" + index} className="nav-link text-dark" target="_blank" rel="noopener noreferrer" data-toggle="collapse">
                            {item.title}
                            <span className="float-right">
                                <img src={caretDown} alt="More Menu" style={{width: "14px"}} />
                            </span>
                        </a>
                        <div id={"child1-" + index} className="collapse">
                            <ul className="nav flex-column">
                                {item.list.map((child1, index1) => {
                                    if (child1.list.length) {
                                        return (
                                            <li className="nav-item pl-3" key={index1}>
                                                <a href="#" className="nav-link text-dark">
                                                    {child1.title}
                                                </a>
                                                <ul className="nav flex-column ml-3">
                                                    {child1.list.map((child2, index2) => (
                                                        <li className="nav-item" key={index2}>
                                                            <a href={child2.url} className="nav-link text-dark" target="_blank" rel="noopener noreferrer">
                                                                {child2.title}
                                                            </a>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </li>
                                        )
                                    } else {
                                        return (
                                            <li className="nav-item pl-3" key={index1}>
                                                <a href={child1.url} className="nav-link text-dark" target="_blank" rel="noopener noreferrer">
                                                    {child1.title}
                                                </a>
                                            </li>
                                        )
                                    }
                                })}
                            </ul>
                        </div>
                    </li>
                )
              } else {
                return (
                    <li className="nav-item" key={index} onClick={() => props.closeDrawer("left-drawer")}>
                        <a href={item.url} className="nav-link text-dark" target="_blank" rel="noopener noreferrer">
                            {item.title}
                        </a>
                    </li>
                )
              }
          })}
        </ul>
      </div>
    </React.Fragment>
  );
}
