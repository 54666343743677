import React from "react";
import { menu } from "../data/listMenu";
import menuIcon from "../svg/menu.svg";
import MobileDrawer from "./MobileDrawer";
import DrawerOverlay from "./DrawerOverlay";
import $ from "jquery";

export default function TopNav() {
  const megaMenu = (list) => {
    let menuClass = "";

    list.forEach((item, index) => {
      if (item.list.length) {
        menuClass = "d-flex flex-row justify-content-between";
      }
    });

    return menuClass;
  };

  const openDrawer = (target) => {
    $("#" + target).removeClass("hide-drawer");
    $("#drawer-overlay").show(200);
  };

  const closeDrawer = (target) => {
    $("#" + target).addClass("hide-drawer");
    $("#drawer-overlay").hide(200)
  };

  return (
    <React.Fragment>
      <nav className="navbar navbar-expand navbar-dark bg-dark shadow sticky-top">
        <div className="container">
          <a className="navbar-brand" href="/">
            <img
              src="https://www.maxindo.net.id/wp-content/uploads/2014/08/favicon.ico"
              className="img-fluid d-inline-block mr-1"
              style={{ width: "34px" }}
              alt="Maxindo Logo"
            />
            <span
              style={{ fontWeight: "lighter" }}
              className="d-none d-lg-inline-block"
            >
              Maxindo Mitra Solusi
            </span>
          </a>

          <ul
            className="navbar-nav ml-auto d-none d-sm-flex"
            onClick={() => closeDrawer("left-drawer")}
          >
            {menu.map((item, index) => {
              if (!item.list.length) {
                return (
                  <li className="nav-item" key={index}>
                    <a
                      href={item.url}
                      className="nav-link"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {item.title}
                    </a>
                  </li>
                );
              } else {
                return (
                  <li className="nav-item dropdown" key={index}>
                    <a
                      href={item.url}
                      className="nav-link dropdown-toggle"
                      data-toggle="dropdown"
                    >
                      {item.title}
                    </a>
                    <div className="dropdown-menu dropdown-menu-right border-0 shadow-sm">
                      <div className={megaMenu(item.list)}>
                        {item.list.map((submenu, idx) => {
                          if (!submenu.list.length) {
                            return (
                              <a
                                href={submenu.url}
                                className="dropdown-item px-4 py-2"
                                key={idx}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {submenu.title}
                              </a>
                            );
                          } else {
                            return (
                              <div key={idx} className="col-3">
                                <h5
                                  className="pb-2 mb-0"
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "400",
                                    borderBottom: "1px dashed #eee",
                                  }}
                                >
                                  {item.list[idx].title}
                                </h5>
                                <ul className="nav flex-column">
                                  {submenu.list.map((child, i) => (
                                    <li className="nav-item" key={i}>
                                      <a
                                        href={child.url}
                                        className="nav-link text-dark text-truncate"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        {child.title}
                                      </a>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            );
                          }
                        })}
                      </div>
                    </div>
                  </li>
                );
              }
            })}
          </ul>
          <a href="#open-menu" onClick={() => openDrawer("left-drawer")}>
            <img
              src={menuIcon}
              alt="menu icon"
              className="img-fluid d-sm-none d-flex"
              style={{ width: "24px" }}
            />
          </a>
        </div>
      </nav>
      <MobileDrawer closeDrawer={closeDrawer} />
      <DrawerOverlay closeDrawer={closeDrawer} />
    </React.Fragment>
  );
}
