import React from 'react'
import {slide} from '../data/listCarousel'

const styles = {
    imageLarge: {
        height: "450px",
        width: "100%"
    },
    imageSmall: {
        height: "220px",
        width: "100%"
    }
}

export default function ContentCarousel() {
    return (
        <div id="contentCarousel" className="carousel slide carousel-fade" data-ride="carousel" data-interval="8000">
            <div className="carousel-inner">
                {slide.map((item, index) => (
                    <div className={"carousel-item" + (index === 0 ? " active" : "")} key={index}>
                        <img src={item.image} alt={item.title} className="img-fluid" style={window.screen.width > 360 ? styles.imageLarge : styles.imageSmall} />
                        <div className="carousel-caption carousel-home text-dark text-left px-4">
                            <h5>{item.title}</h5>
                            <p>{item.subtitle}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}
