import React from 'react'
import TopNav from './components/TopNav'
import LoginForm from './components/LoginForm'
import ContentCarousel from './components/ContentCarousel'

export default function App() {

  React.useEffect(() => {
    fetch("/controller/ajax/login_page.php")
      .then(response => response.json())
      .then(data => {
        if (data.status) {
          window.location.href = "https://user.maxindo.net.id/index.php"
        }
      })
  })

  return (
    <div>
      <TopNav />
      <div className="container mt-5">
        {/* Home Section */}
        <div className="row">
          <div className="col-lg-7 col-md-7 col-12 mb-3 d-none d-md-block">
            <ContentCarousel />
          </div>
          <div className="col-md-1 d-none d-lg-block"></div>
          <div className="col-lg-4 col-md-5 col-12">
            <LoginForm />
          </div>
          <div className="col-12 d-block d-sm-none">
            <ContentCarousel />
          </div>
        </div>
      </div>
    </div>
  )
}
