export const menu = [
    {
        title: "Home",
        url: "https://maxindo.net.id",
        list: []
    },
    {
        title: "About",
        url: "https://maxindo.net.id/about/",
        list: []
    },
    {
        title: "Services",
        url: "#",
        list: [
            {
                title: "Maintenance Server",
                url: "https://maxindo.net.id/maintenance-server/",
                list: []
            },
            {
                title: "Monitoring Service",
                url: "https://maxindo.net.id/monitoring-service/",
                list: []
            },
            {
                title: "Network Designer",
                url: "https://maxindo.net.id/network-designer/",
                list: []
            },
            {
                title: "IT Consultant",
                url: "https://maxindo.net.id/it-consultant/",
                list: []
            },
        ]
    },
    {
        title: "Product",
        url: "#",
        list: [
            {
                title: "Layanan Jaringan",
                url: "#",
                list: [
                    {
                        title: "Internet Access",
                        url: "https://maxindo.net.id/internet-access/",
                        list: []
                    },
                    {
                        title: "Wifi & Hotspot",
                        url: "https://maxindo.net.id/wifi-hotspot/",
                        list: []
                    },
                    {
                        title: "Collocation",
                        url: "https://maxindo.net.id/collocation/",
                        list: []
                    }
                ]
            },
            {
                title: "Layanan Data Center",
                url: "#",
                list: [
                    {
                        title: "VPS",
                        url: "https://maxindo.net.id/vps/",
                        list: []
                    },
                    {
                        title: "Dedicated",
                        url: "https://maxindo.net.id/dedicated/",
                        list: []
                    },
                    {
                        title: "Web Hosting",
                        url: "https://maxindo.net.id/web-hosting/",
                        list: []
                    }
                ]
            },
            {
                title: "Layanan Internet",
                url: "#",
                list: [
                    {
                        title: "VPN",
                        url: "https://maxindo.net.id/vpn/",
                        list: []
                    },
                    {
                        title: "Mail Server",
                        url: "https://maxindo.net.id/mail-server/",
                        list: []
                    },
                    {
                        title: "Shoutcast Radio Maxindo",
                        url: "https://maxindo.net.id/shoutcast-radio-maxindo/",
                        list: []
                    }
                ]
            },
            {
                title: "Layanan Software",
                url: "#",
                list: [
                    {
                        title: "ERP",
                        url: "https://maxindo.net.id/erp/",
                        list: []
                    }
                ]
            }
        ]
    },
    {
        title: "Partnership",
        url: "#",
        list: [
            {
                title: "MCS",
                url: "http://mcs.co.id",
                list: []
            },
            {
                title: "GDI",
                url: "http://www.gdi.co.id",
                list: []
            },
            {
                title: "Cpanel",
                url: "http://cpanel.co.id",
                list: []
            }
        ]
    },
    {
        title: "Career",
        url: "https://maxindo.net.id/career/",
        list: []
    },
    {
        title: "Contact",
        url: "https://maxindo.net.id/contact/",
        list: []
    }
]