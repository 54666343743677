import internet_usage from '../svg/internet_usage.svg'
import login_password from '../svg/login_password.svg'
import available_device from '../svg/available_device.svg'
import many_more from '../svg/many_more.svg'

export const slide = [
    {
        title: "Internet usage",
        subtitle: "You can monitoring your internet usage",
        image: internet_usage
    },
    {
        title: "Change your login password",
        subtitle: "Easy to change your account password",
        image: login_password
    },
    {
        title: "See available device",
        subtitle: "Listing your available device",
        image: available_device
    },
    {
        title: "Many more",
        subtitle: "You can access full featured after login",
        image: many_more
    }
]